import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/aftersun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/aftersun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-aftersun-product.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-aftersun-product-mov.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> AFTER SUN
                    </h1>
                    <div className="products-page__intro__description">
                      <p>
                        <strong>
                          Revitalización de la piel después del sol
                        </strong>
                      </p>
                      <p>
                        <strong />
                        Revitalización de la piel después del sol. Después de
                        todo un día al sol, su piel necesita revitalización e
                        hidratación. Cuide su piel con nuestros productos para
                        después del sol con el fin de restaurar su equilibrio de
                        hidratación natural. Enriquecido con un poderoso
                        Complejo Hidratante, PIZ BUIN<sup>®</sup> AFTER SUN
                        ayuda a proteger la piel de la deshidratación y a
                        conseguir un bronceado más duradero y hermoso. Todos los
                        productos PIZ BUIN<sup>®</sup> AFTER SUN contienen
                        Vitamina E, que ayuda a proteger la piel contra el
                        envejecimiento prematuro asociado a la exposición al
                        sol.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="after-sun-loci-n-hidratante-calmante-y-refrescante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-400ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        AFTER SUN LOCIÓN HIDRATANTE, CALMANTE Y REFRESCANTE
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Enriquecida con Aloe Vera, la nueva PIZ BUIN
                          <sup>®</sup> AFTER SUN Loción Hidratante, Calmante y
                          Refrescante proporciona 24h de hidratación para
                          restaurar el equilibrio natural de la hidratación de
                          la piel estresada por el sol y ayuda a prevenir la
                          descamación con el fin de lograr un bronceado más
                          duradero. Su fórmula fresca, no grasa y de rápida
                          absorción contiene extracto de menta para obtener un
                          efecto refrescante inmediato y duradero.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            La nueva PIZ BUIN<sup>®</sup> AFTER SUN Loción
                            Hidratante, Calmante y Refrescante ha sido valorada
                            favorablemente por los consumidores. Les pedimos su
                            opinión sobre la nueva PIZ BUIN<sup>®</sup> AFTER
                            SUN Loción Hidratante, Calmante y Refrescante*.
                          </p>
                          <p>
                            <strong>
                              <span className="orange">El 100%</span>
                            </strong>{" "}
                            experimentaron:
                          </p>
                          <ul>
                            <li>Piel fresca, calmada e hidratada.</li>
                            <li>Piel visiblemente más suave</li>
                          </ul>
                          <div>
                            Más del{" "}
                            <strong>
                              <span className="orange">90%</span>
                            </strong>{" "}
                            experimentaron:
                          </div>
                          <ul>
                            <li>Un efecto refrescante inmediato.</li>
                            <li>Piel inmediatamente calmada y visiblemente</li>
                          </ul>
                          <div>más suave:</div>
                          <div>
                            <ul>
                              <li>Piel inmediatamente reconfortada</li>
                              <li>
                                Restauración del equilibrio de hidratación
                                natural{" "}
                              </li>
                            </ul>
                          </div>
                          <h2>Disponible en formato</h2>
                          <p>200 ml y 400 ml</p>
                          <p className="footNote">
                            *Autoevaluación, 19 sujetos, 3 semanas
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ALOE VERA
                                <div className="textBlock bottom">
                                  <p>
                                    ¿Por qué es tan beneficioso el Aloe Vera?
                                  </p>
                                  <p>
                                    El Aloe Vera es un agente hidratante natural
                                    con potentes propiedades calmantes.{" "}
                                  </p>
                                  <p>
                                    <em>
                                      El Aloe Vera está presente en el producto
                                      PIZ BUIN<sup>®</sup> Moisturizing Stick
                                      Labial con Aloe Vera
                                    </em>
                                  </p>
                                  <p>
                                    <em>
                                      El Aloe Vera está presente en el producto
                                      PIZ BUIN<sup>®</sup> After Sun Loción
                                      Hidratante, Calmante y Refrescante
                                    </em>
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina atrae y retiene el agua en la
                                    piel para ayudar a mantener un buen nivel de
                                    hidratación.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                EXTRACTO DE MENTA
                                <div className="textBlock bottom">
                                  <p>
                                    La menta se utiliza ampliamente en distintas
                                    aplicaciones cosméticas. Entre otras, es
                                    conocida por sus grandes propiedades
                                    refrescantes naturales.
                                  </p>
                                  <p>
                                    <em>
                                      El Extracto de Menta está presente en el
                                      producto PIZ BUIN<sup>®</sup> After Sun
                                      Loción Hidratante, Calmante y Refrescante.
                                    </em>
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fdf3033b2f5f4b9f6c2f8a54acf02d47_f45.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="loci-n-hidratante-intensificadora-del-bronceado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOCIÓN HIDRATANTE INTENSIFICADORA DEL BRONCEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          La nueva PIZ BUIN<sup>®</sup> AFTER SUN Loción
                          Hidratante Intensificadora del Bronceado contiene
                          Tanimel, un extracto de plantas intensificador del
                          bronceado que ayuda a duplicar la producción de
                          pigmentos de bronceado natural de la piel*. La fórmula
                          de rápida absorción proporciona 24h de hidratación
                          para restaurar el equilibrio natural de la hidratación
                          de la piel estresada a causa del sol y ayuda a
                          prevenir la descamación para lograr un bronceado más
                          duradero.
                          <br />
                          <span className="footNote">*Pruebas in vitro</span>
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <h2>Disponible en formato</h2>
                          <p>200 ml</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina atrae y retiene el agua en la
                                    piel para ayudar a mantener un buen nivel de
                                    hidratación.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                MANTECA DE KARITÉ
                                <div className="textBlock bottom">
                                  <p>
                                    La manteca de karité se extrae del árbol de
                                    la nuez del karité, que crece en África
                                    occidental. Durante siglos, se ha usado para
                                    hidratar y proteger la piel de los efectos
                                    del sol, el viento, el calor y el agua
                                    salada. También se utiliza ampliamente en
                                    cosmética debido a sus propiedades
                                    hidratantes y protectoras.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TANIMEL
                                <div className="textBlock bottom">
                                  <p>
                                    El Tanimel es un ingrediente activo extraído
                                    de la planta Ononis Spinosa, común en
                                    Europa. Ayuda a mantener la producción de
                                    melanina natural del cuerpo durante y
                                    después de la exposición al sol,
                                    consiguiendo un bronceado natural más
                                    duradero*. También incrementa la producción
                                    de melanina en hasta un 133%*, lo cual
                                    provoca un bronceado más intenso.
                                  </p>
                                  <p className="footNote">*Pruebas in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/6121365f8827c636e3a96d865c44ae54_f47.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="instant-mist-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>PIZ BUIN® After Sun Instant Relief Mist Spray </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Con ácido hialurónico y glicerina. <br />
                          Proporciona 48 h de hidratación: Ayuda a restaurar el
                          equilibrio de hidratación natural de la piel
                          deshidratada por el sol. <br />
                          Ayuda a prevenir la descamación para obtener un
                          bronceado más duradero. <br />
                          Textura ultraligera. <br />
                          Rápida absorción. No pegajoso y no graso. <br />
                          Frescor calmante que alivia la piel.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <h2>Disponible en formato</h2>
                          <p>200 ml</p>
                        </div>
                        <div className="products-page__product__details__directions">
                          <h2>Aplicación</h2>
                          <p>
                            Para 48h de hidratación, reaplicar después de la
                            ducha o el baño, tras secar la piel con una toalla.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, Glycerin, Isopropyl Palmitate,
                                    Petrolatum, Dimethicone, Hydrolyzed
                                    Hyaluronic Acid, Isohexadecane, C12-20 Alkyl
                                    Glucoside, Polysorbate 60, Sorbitan
                                    Isostearate, C14-22 Alcohols, Hydroxyethyl
                                    Acrylate/Sodium Acryloyldimethyl Taurate
                                    Copolymer, Sodium Hydroxide, Tocopherol,
                                    Benzyl Alcohol, Chlorphenesin, Parfum.
                                    [PR017621]
                                  </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/es/nuestros-productos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/es/nuestros-productos/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/es/nuestros-productos/tan-protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/es/nuestros-productos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/es/nuestros-productos/after-sun/"
                      target="_self"
                    >
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/galeria/" target="_self">
                      Galeria
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Lee más');\n} else {\nbox.slideDown();\n$(this).text('Leer menos');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
